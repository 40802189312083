<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <div>
            <van-pull-refresh  class="liveCourse" v-model="refreshing" @refresh="onRefresh">
                <van-search
                        class="custom_search"
                        v-model="listQuery.name"
                        left-icon=""
                        background="transparent"
                        placeholder="请输入课程名称"
                        @search="onSearch">
                    <template slot="right-icon">
                        <van-icon @click="onSearch" name="search"/>
                    </template>
                </van-search>
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad">
                    <BarList :list="list" :type="pageType.course"
                             :propType="propType"
                             @callMore="(item)=>{callMore(item, '/courseDetails')}"/>
                </van-list>
            </van-pull-refresh>
        </div>
    </baseContainer>
</template>

<script>
import HeadNav from './../../components/headNav';
import BarList from './components/BarList';
import constant from './../../utils/constant';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import api from './../../api/home';

export default {
    name: "liveCourse",
    components: {
        HeadNav,
        BarList
    },
    mixins: [DropDownRefresh],
    data () {
        return {
            pageType: constant.pageType,
            listQuery: {
                name: '',
                current: 1,
                size: 5
            },
            propType: {
                img: 'courseCover',
                name: 'name',
                date: 'startTime',
                user: 'teacherName',
                status: 'status'
            }
        }
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url);
        },
        // 查询首页直播列表
        getList () {
            api.getCourseLists(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    },
    mounted () {
    }
}
</script>

<style scoped lang="less">
    .liveCourse {
        padding: 20px 40px 40px;
        background: #FCFCFC;
    }
</style>